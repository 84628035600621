<template>
  <div>
    <general-table
      ref="friendsTable"
      :api-url="APIURL"
      :view-content="false"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :type="type"
      :columns="columns"
      :selectable="false"
    >
      <template
        #cell(active)="data"
      >
        <b-badge
          class="change-status"
          :variant="changeVaraint(data.item.active)"
        >
          {{ getTypes(data.item.active) }}
        </b-badge>
      </template>
      <template
        #cell(feathered)="data"
      >
        <!-- <b-badge
          class="change-status"
          pill
          :variant="changeVaraint(data.item.feathered)"
        >
          {{ getFeatured(data.item.feathered) }}
        </b-badge> -->
        <div :class="data.item.feathered ===1 ? 'text-success' : 'text-danger'">
          {{ getFeatured(data.item.feathered) }}
        </div>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'admin/campaigns',
      editComponent: 'edit-campaign-types',
      viewContent: true,
      type: 'page',
      columns: [
        { key: 'id', sortable: true },
        { key: 'name', label: 'Name' },
        { key: 'description', label: 'Description' },
      ],
    }
  },
  methods: {
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type === 1) return 'success'

      return 'danger'
    },
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
    getTypes(type) {
      if (type === 1) {
        return 'Active'
      } if (type === 0) {
        return 'Not Active'
      }
      return 'Active'
    },
    getFeatured(type) {
      if (type === 1) {
        return 'Featured'
      } if (type === 0) {
        return 'Not Featured'
      }
      return 'Featured'
    },
  },

}
</script>

<style>

</style>
